<script>
  export default {

  }
</script>

<template>
  <section>
    <h3>Homepage</h3>
  </section>
</template>